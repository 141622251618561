export enum FeatureNames {
  VerificationJourney = "verification-journey",
}
interface ConfigProps {
  env: "production" | "uat" | "dev" | "local";
  api: {
    host: string;
  };
  contactInfo: {
    supportEmail: string;
    privacyEmail: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  features: FeatureNames[];
}

const env = (process.env.REACT_APP_ENVIRONMENT ||
  "local") as ConfigProps["env"];

const localConfig: ConfigProps = {
  env,
  api: {
    host: process.env.REACT_APP_API_HOST || "http://localhost:5000/",
  },
  firebase: {
    apiKey: "AIzaSyD8LLRu_HBgm2GcsT0n7TlTxUGslp-mjJk",
    authDomain: "verified-platform.firebaseapp.com",
    projectId: "verified-platform",
    storageBucket: "verified-platform.appspot.com",
    messagingSenderId: "600956850721",
    appId: "",
    measurementId: "",
  },
  contactInfo: {
    supportEmail: "info@verifiedtoken.com",
    privacyEmail: "privacy@verifiedtoken.com",
  },
  features: Object.values(FeatureNames).map((value) => value),
};

const devConfig: ConfigProps = {
  ...localConfig,
  firebase: {
    ...localConfig.firebase,
    appId: "1:600956850721:web:8e18787fb99b407c340960",
    measurementId: "UA-162250836-2",
  },
};

const uatConfig: ConfigProps = {
  ...devConfig,
  features: [FeatureNames.VerificationJourney],
};

const productionConfig: ConfigProps = {
  ...uatConfig,
  firebase: {
    ...uatConfig.firebase,
    appId: "1:600956850721:web:96aec47e275175da340960",
    measurementId: "UA-162250836-1",
  },
  features: [],
};

export const config = {
  local: localConfig,
  dev: devConfig,
  uat: uatConfig,
  production: productionConfig,
}[env];

export function isFeatured(feature: FeatureNames) {
  return config.features.includes(feature);
}
