import { RoleAttributes, TokenAttributes } from "vt-types";
import { formatNumber, formatRating, formatDifficulty } from "./format";

export const companyName = "VerifiedToken";
export const homeButtonLabel = "home";

export function getRoleStats(role: Partial<RoleAttributes>) {
  const payload = [];

  if (role.courses) {
    payload.push({
      value: formatNumber(role.courses.length),
      label: `Course${role.courses.length > 1 ? "s" : ""}`,
    });
  }

  if (role.salary) {
    payload.push({
      value: `£ ${formatNumber(role.salary)}`,
      label: "Min. pay",
    });
  }

  if (role.complexity) {
    payload.push({
      value: formatDifficulty(role.complexity),
      label: "Difficulty",
    });
  }

  return payload;
}

export function getTokenStats(token: Partial<TokenAttributes>) {
  const payload = [
    {
      value: token.type ?? "Unknown",
      label: "Type",
    },
  ];

  if (token.rating) {
    payload.push({
      value: `${formatRating(token.rating)}`,
      label: "Popularity",
    });
  }

  if (token.complexity) {
    payload.push({
      value: formatDifficulty(token.complexity),
      label: "Difficulty", // "Learning Curve",
    });
  }

  return payload;
}

export function getLoadButtonLabel(pagination: number) {
  const prefix = "load ";

  if (pagination >= 8) {
    return `${prefix} much much much more`;
  }

  if (pagination >= 5) {
    return `${prefix} much much more`;
  }

  if (pagination >= 3) {
    return `${prefix} much more`;
  }

  return `${prefix} more`;
}

export function getChallengeTitle(totalQuestions: number, currentStep: number) {
  const lastQuestion = totalQuestions - currentStep <= 1;
  if (lastQuestion) {
    return "Last one!";
  }

  const checkpoint = Math.floor(totalQuestions / 2) === currentStep;
  if (checkpoint) {
    return "Doing great!";
  }

  const checkpointB =
    totalQuestions > 30 && Math.floor(totalQuestions / 3) === currentStep;

  if (checkpointB) {
    return "Look at you go!";
  }

  return `${currentStep}/${totalQuestions}`;
}
